$public: "../../public";

@font-face {
    font-family: "Montserrat";
    src: url($public + "/fonts/Montserrat-Thin.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("#{$public}/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("#{$public}/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("#{$public}/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: "Montserrat";
    src: url("#{$public}/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: black;
}

@font-face {
    font-family: "Montserrat";
    src: url("#{$public}/fonts/Montserrat-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: black;
}

@import "../../node_modules/flag-icons/css/flag-icons.min.css";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import "colours.scss";

html {
    overflow: hidden;
}

.btn-primary {
    background: var(--parla-primary);
    border: 1px solid transparent;

    &:disabled {
        background: var(--parla-primary);
        opacity: 0.5;
    }

    &:hover {
        color: var(--parla-primary);
        border-color: var(--parla-primary);
        background-color: transparent;
    }
}

.btn-outline-primary {
    color: var(--parla-primary);
    border: 1px solid var(--parla-primary);

    &:disabled {
        opacity: 0.5;
    }

    &:hover {
        border-color: transparent;
        color: #fff;
        background-color: var(--parla-primary);
    }
}

.btn-secondary {
    border-style: none;
    background: var(--parla-secondary);
}

.btn-tertiary {
    background: var(--parla-tertiary);
    border: 1px solid transparent;
    color: #fff;
    border-radius: 10px;
    padding: 8px 11px;

    &:hover {
        color: var(--parla-tertiary);
        border-color: var(--parla-tertiary);
        background-color: transparent;
    }

    &:disabled {
        background: var(--parla-tertiary);
        opacity: 0.5;
    }

    i {
        margin-right: 10px;
    }
}

.btn-white {
    background: #fff;
    border-style: none;
    color: var(--parla-tertiary);
    border-radius: 10px;
    padding: 8px 11px;
    border: 1px solid var(--parla-tertiary);

    &:disabled {
        background: #fff;
        opacity: 0.5;
    }

    i {
        margin-right: 10px;
    }
}

.btn-danger {
    background: var(--parla-red);
    border: 1px solid transparent;

    &:hover {
        border-color: var(--parla-red);
        color: var(--parla-red);
        background-color: transparent;
    }

    &:disabled {
        background: var(--parla-red);
        opacity: 0.5;
    }
}

.btn-outline-danger {
    color: var(--parla-red);
    border: 1px solid var(--parla-red);

    &:disabled {
        border: 1px solid var(--parla-red);
        opacity: 0.5;
    }

    &:hover {
        border-color: transparent;
        color: #fff;
        background-color: var(--parla-red);
    }
}


.btn-import-csv {
    background: #fff;
    border-style: none;
    color: var(--parla-tertiary);
    border-radius: 10px;
    padding: 8px 11px;
    border: 1px solid var(--parla-tertiary);

    &:disabled {
        background: #fff;
        opacity: 0.5;
    }

    i {
        margin-right: 10px;
    }
}

body {
    font-family: "Montserrat";
    background-color: #fff;

    .tabs-container {
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        height: 100%;
    }

    .table-page {
        width: 90%;
    }

    .app-wrapper {
        background-color: #f8f8f8;
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: 150px;

        .flex {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    .content-wrapper {
        margin-top: 0px;
        height: calc(100vh - 66px);
        overflow: auto;
        margin-bottom: 100px;
    }

    .logo {
        width: 100px;
    }

    .navbar {
        border-bottom: 1px solid #eff2f5;
        max-height: 60px;
        min-height: 60px;
        background: #fff;
        z-index: 3;

        .menu-icon-tags {
            display: none;
        }
    }

    .navbar-options {
        display: contents;
    }

    .flex {
        display: flex;
    }

    .customer-address {
        .modal-header {
            justify-content: space-between;
        }

        .address-selector {
            .address {
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-top: 15px;
            }
        }
    }

    .modal-dialog {
        max-width: 850px;

        .modal-content {
            //Subtract the right margin to allow space for scrollbars
            padding: 40px 30px 40px 40px;

            .fixed-height {
                padding-right: 10px;
            }

            .mini-address-card {
                border: 1px solid #ccc;
                border-radius: 12px;
                line-height: 20px;
                font-size: 14px;
                padding: 15px;
                margin: 10px 0;

                label {
                    padding-right: 5px;
                }
            }
        }

        .modal-header {
            border: none;
            justify-content: space-between;
        }

        .modal-footer {
            border: none;
        }

        input:not([type="range"]) {
            border-radius: 4px;
            background-color: transparent;
            border: 1px solid #ced4da;
            color: #495057;
        }

        .uploader-component {
            padding: 0 10px 10px 10px;
        }

        .date-time-picker {
            input {
                border: none !important;
                min-width: 0.54em;
                height: calc(100% - 2px);
                position: relative;
            }
        }

        input[type="file"] {
            display: none;
        }

        .custom-file-upload {
            border: none;
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            border-radius: 5px;
            background-color: var(--parla-primary);
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            position: inherit;
        }

        .file-button-wrap {
            position: relative;

            input {
                position: absolute;
                z-index: 0;
                top: -40px;
                left: -2px;
                font-size: 16px;
                line-height: 14px;
                height: 59px;
                color: #b8b8b8;
                overflow: hidden;
            }
        }

        .modal-btn {
            display: inline-block;
            cursor: pointer;
            border-radius: 5px;
            background-color: var(--parla-primary);
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            position: inherit;
            height: 38px;
            border: none;
        }

        .field {
            display: flex;
            flex-direction: column;
        }

        .validation-error {
            color: red;
        }

        .hidden {
            display: block;
            visibility: hidden;
            top: -24px;
            position: relative;
        }

        .close {
            font-size: 2rem;
        }
    }

    label {
        font-weight: 600;
    }

    .container {
        margin-top: 25px;
        border-radius: 4px;
        width: 90%;
        max-width: 2000px;
        background: #fff;
        height: fit-content;
    }

    .dashboard-container {
        background: unset;
        margin-top: 10px;
        width: 100vw;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        .dashboard-block {
            flex: 1 1 calc(33.33% - 15px);
            margin: 0 15px 15px 0;
            box-sizing: border-box;

            .department-availability-wrap {
                height: 215px;
                overflow: auto;
            }
        }
    }

    .tab-content {
        height: 100%;

        .container {
            margin-top: 0;
            border: none;
            background: #fff;
            width: 100%;
            max-width: 2000px;
        }
    }

    .nav-tabs {
        border-radius: initial;
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background: #fff;
        border: none;
        font-size: 15px;

        .nav-item {
            border: 1px solid #d2d6db;
            border-top: none;
            border-left: none;

            .nav-link {
                border-radius: initial;
                color: #727272;
                font-weight: 600;
                border: none;

                &:hover,
                &:focus {
                    border: none;
                }
            }
        }

        a {
            color: #102e4a;
            cursor: pointer;
            font-size: 13px;
            padding: 7px 25px;
            border-radius: initial;
            text-decoration: none;
        }

        .selected {
            border-top: 3px solid var(--parla-primary) !important;
            font-weight: 600;
        }
    }

    button:focus {
        outline: none;
        outline: none;
    }
}

// APPLE SCROLL OVERRIDES
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #d6d6d6;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    display: block;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.env-banner {
    background: var(--parla-primary);
    padding: 5px;
    position: absolute;
    z-index: 9999;
    width: 243px;
    font-size: 15pt;
    font-weight: 400;
    text-align: center;
    color: #fff;
    left: 40px;
    top: 40%;
    transform: rotate(90deg);
    transform-origin: top left;
}

.md-editor {
    .rc-md-editor .editor-container .sec-html .html-wrap {
        background: #f7f7f7;
        cursor: default;
    }
}

.CSVImporter_FormatRawPreview__scroll {
    margin-bottom: 1.2em;
    height: 6em;
    overflow: auto;
    border-radius: 0.4em;
    background: none !important;
    color: #f0f0f0;
}

.CSVImporter_FormatRawPreview__pre {
    background: #f7f7f7;
}

.asset-content {
    display: flex;
    margin: 30px;
}

.main-asset {
    height: 600px;
    width: fit-content;
    position: relative;
    cursor: crosshair;
    padding: 0;

    .asset-parent-wrapper {
        opacity: 0.3;
    }

    .center {
        position: absolute;
        line-height: 16px;
        text-shadow: 0 0 2px white;
        font-size: 30px;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: none;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--parla-primary);
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: inherit;
}

.missing-component {
    font-size: smaller;
    color: darkgrey;
    font-style: italic;
}

.log {
    font-size: 8px;
    max-width: 340px;
    word-wrap: normal;
    word-break: break-all;
}

.modal-wrapper {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0000005e;
    z-index: 10;
    justify-content: center;
    align-items: center;

    .modal {
        display: block;
        height: unset;
        width: 670px;
        max-height: 90vh;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        background: #fff;
        padding: 25px;
        border-radius: 12px;
        position: relative;

        .selected-staff {
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
            margin: 10px 0;
            display: flex;
            overflow: scroll;

            .selected-photo-item {
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 8px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                margin-right: 5px;

                .btn-sm {
                    margin: 0;
                    margin-left: 8px;
                }
            }
        }

        .staff-list {
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
            margin: 10px 0;

            .staff-list-card {
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 4px 10px;
                margin: 2px 0;
                color: #333;
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    background-color: #ccc;
                    font-weight: 600;
                }
            }
        }

        .filters {
            display: flex;
            justify-content: space-between;
        }

        select {
            padding: 2px 5px;
            margin-left: 5px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 14px;
        }

        .search-wrapper {
            input {
                padding: 2px 5px;
                margin-left: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 14px;
            }
        }

        .modal-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .close {
            font-size: 22px;
            cursor: pointer;
        }
    }
}

.no-border {
    border: none !important;
}

.accordion {
    margin: 0em !important;

    .accordion-header.header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1rem 1.25rem;
        // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);

        .title {
            display: flex;
            flex-direction: column;
            gap: 0.5em;

            label {
                color: var(--TERTIARY-DARK_BLUE, #102e4a);
                font-family: Montserrat;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            span {
                color: var(--ACCENT-SHADE_OF_BLACK_3, #727272);
                font-family: Montserrat;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        &.closed {
            border-radius: 6px;
        }
    }
}

.rdt_TableCell {
    &:has(.virtual-service-marker) {
        label {
            display: none;
        }

        i {
            display: block;
        }
    }
}

@media only screen and (max-width: 990px) {
    #atlwdg-trigger {
        display: none;
    }

    body {
        .content-wrapper {
            margin-top: 0px;
            height: calc(100vh - 25px);
        }

        .nav-tabs {
            flex-wrap: nowrap;
            max-width: 100%;
            overflow: auto;
            padding-bottom: 0.2rem;

            .nav-link {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: auto !important;
            }
        }

        .help-icon {
            position: absolute;
            right: 15px;
            top: 65px;
            font-size: 20px;
        }

        .rdt_TableHead {
            display: none;
        }

        .rdt_TableRow {
            display: flex;
            flex-direction: column;
            min-height: 200px;
            height: auto;
            padding: 8px 0px;
            background: #f7f7f7;
            border-radius: 8px;
            margin-bottom: 5px;
            border-bottom-style: none;
            border-bottom-width: 0px;
            border-bottom-color: transparent;

            .name {
                font-weight: 600;
                font-size: 20px;
            }

            .rdt_TableCell {
                &:has(input) {
                    position: absolute;
                    right: 0rem;
                    width: 0;
                    max-width: 0;
                    z-index: 2;
                }

                &:has(.virtual-service-marker) {
                    label {
                        display: block;
                        font-size: 10px;
                        font-weight: 600;
                        color: #1E9CE8;
                        padding: 0.2rem 0.3rem;
                        background-color: #EEF7FF;
                        border: 1px solid #d7f0ff;
                        border-radius: 0.2rem;
                    }

                    i {
                        display: none;
                    }
                }

                &:first-child {
                    flex-basis: unset;
                }
            }
        }
    }
}

.table-mobile-view {
    padding: 0;

    @media screen and (max-width:768px) {
        padding: 0;

        header {
            padding: 15px 15px 0;

            .table-toolbar-controls {
                position: relative;
                gap: 0.3rem;
                flex-direction: row;

                .searchTerm {
                    width: calc(100% - 2rem);
                }

                .input-group-prepend {
                    padding: 0;
                    border-radius: 8px;
                    background: var(--parla-secondary);

                    i.input-group-text {
                        color: white;
                        padding: 1rem;
                    }
                }

                .table-toolbar-controls-wrap {

                    button,
                    button i {
                        margin: 0;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    body {
        .content-wrapper {
            margin-top: 0px;
            padding-bottom: 2rem;
        }

        .nav-tabs {
            flex-wrap: nowrap;

            .nav-link {
                max-width: 100%;
                overflow: hidden;
                text-overflow: clip;
                white-space: nowrap;
                flex-grow: 1;
                display: flex;
                align-self: center;
                justify-content: center;
                text-align: center;

                i {
                    width: 25px;
                }

                span {
                    display: none;
                }
            }
        }

        .modal-dialog {
            .modal-content {
                padding: 15px;
            }

            .modal-header {
                padding-bottom: 0;
            }
        }
    }

    .btn-import-csv {
        font-size: 12px;
    }

}

// added to focus on modal over another modal
div[role="dialog"] {
    background: #0000005e;
}