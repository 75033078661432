.dashboard-block {
    .dashboard-card-sm-title {
        padding: 24px 16px 10px;
    }

    .free-plan {
        display: inline-flex;
        height: 4.25rem;
        padding: 0.875rem 1rem 0.875rem 1.1875rem;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0.625rem;
        background: linear-gradient(90deg, #1e9ce8 0%, #f40e6f 100%);
        margin: 0 15px;

        span {
            color: #f8f8f8;
            text-align: center;
            font-family: Montserrat;
            font-size: 0.6875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        h5 {
            color: #fff;
            text-align: center;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0;
            white-space: nowrap;
        }

        button {
            color: var(--SECONDARY-PINK, #f40e6f);
            text-align: center;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 0.625rem;
            background: var(--ACCENT-WHITE, #fff);
            border: none;
            width: 8.75rem;
            height: 2.5rem;
            flex-shrink: 0;
        }
    }
}

.plan-counter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    gap: 0.5em;

    .usage {
        width: 140px;
        font-size: 14px;
        padding-bottom: 5px;
        min-width: 13em;

        &.account {
            display: flex;
            padding: 0.875rem 0.5rem 0.875rem 0.5rem;
            align-items: start;
            border-radius: 0.625rem;
            background: var(--ACCENT-WHITE, #fff);

            .icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.5rem;
                height: 2.5rem;
                background-color: #eef7ff;
                border-radius: 0.63em;
                margin-right: 0.5em;
                min-width: 2.5rem;

                i {
                    color: #1e9ce8;
                }
            }

            .usage-title {
                color: #102e4a;

                /* PARAGRAPH/BOLD */
                font-family: Montserrat;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .usage-value {
                color: var(--ACCENT-SHADE_OF_BLACK_4, #4c4c4c);

                /* SMALL TEXT/MEDIUM */
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-wrap: balance;
            }
        }
    }
}

// Plan Modal
.pricing-modal {
    z-index: 9999;

    .modal-dialog .modal-content {
        padding: 25px;
    }

    .modal-title.h4 {
        color: #1e9ce8;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    .hero .title-2 {
        white-space: nowrap;
        line-height: 60px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        color: #2e2e2e;
    }

    .pricing-card-preview-wrapper {
        display: flex;
    }

    .pricing-card-number-users {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
    }

    .pricing-card-month-year {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 0px;
        color: #1e9ce8;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: 2px solid #1e9ce8;
        width: fit-content;
        border-radius: 10px;
        padding: 5px;
        margin-bottom: 10px;

        div {
            padding: 10px 25px;
            cursor: pointer;
        }

        .active {
            background: #1e9ce8;
            color: #fff;
            border-radius: 10px;
        }
    }

    .pricing-card-number-users {
        border-radius: 10px;
        background: #f8f8f8;
        padding: 12px 125px 12px 125px;
        color: #102e4a;
    }

    .pricing-card-number-users label {
        font-weight: 600;
    }

    .pricing-card-number-users input {
        width: 50px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #999;
        margin: 0 5px;
        text-align: center;
    }

    .pricing-card-month-year label {
        font-weight: 600;
        padding: 0 10px;
    }

    .pricing-card-discount {
        color: #39cf6c;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }

    .pricing-card-item {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 8px 15px rgba(125, 147, 178, 0.25);
        transition: all 0.3s;
        padding: 20px;
        margin-top: 15px;
        margin-right: 25px;
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #ececec;
        position: relative;

        &.popular {
            background: #102e4a;

            .pricing-card-item-subtitle {
                color: #fff;
            }

            .pricing-card-item-price {
                color: #fff;
            }
        }

        .popular-tag {
            border-radius: 5px;
            background: #f40e6f;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            position: absolute;
            right: 2px;
            top: 2px;
            padding: 5px 11px 5px 11px;
        }

        .pricing-card-item-title {
            font-size: 25px;
            font-weight: 700;
            padding: 0;
            color: #1e9ce8;
        }

        .pricing-card-item-subtitle {
            color: #102e4a;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .pricing-card-item-price {
            font-size: 25px;
            padding: 0;
            font-weight: 600;
            color: #102e4a;
        }

        .pricing-card-item-price-enterprice {
            font-size: 16px;
            text-align: center;
            font-weight: 600;
            color: #102e4a;
            padding: 35px 0;
        }

        .pricing-card-item-subtitle-price {
            border-radius: 10px;
            font-size: 14px;
            font-weight: 700;
            color: #39cf6c;
            margin: 0 auto;
            margin-bottom: 0px;
            margin-bottom: 10px;
            text-align: left;
        }

        .pricing-card-item-description {
            margin-bottom: 20px;
        }

        .pricing-card-item-description span {
            color: #1e9ce8;
            display: block;
            font-size: 14px;
            padding-bottom: 5px;

            i {
                padding-right: 5px;
            }
        }

        .pricing-card-item-hr {
            width: 100%;
            height: 2px;
            background: #d2d6db;
            margin: 10px 0 15px;
        }
    }

    .pricing-card-item:last-child {
        margin-right: 0;
    }

    .pricing-card-item-btn {
        align-items: center;
        background: #1e9be8;
        display: flex;
        justify-content: center;
        color: #fff;
        border-radius: 10px;
        padding: 9.5px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }

    .contact-form {
        .contact-form-title-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .contact-form-title {
                color: #1e9ce8;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 20px;
            }
        }

        .form-group {
            label {
                color: #4c4c4c;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            input {
                background: #fff;
                border: 1px solid gray;
                border-radius: 10px;
                color: #102e4a;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10px;
                padding: 19px 15px;
            }

            textarea {
                background: #fff;
                border: 1px solid gray;
                border-radius: 10px;
                color: #102e4a;
                font-size: 12px;
                font-style: normal;
                min-height: 150px;
            }
        }

        .contact-form-wrap {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .contact-form-back {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: #fff;
                border-radius: 10px;
                border: 2px solid var(--parla-primary);
                color: var(--parla-primary);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 4px 50px;
                height: 40px;
                margin-right: 10px;
                cursor: pointer;
            }

            .contact-form-send {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: var(--parla-primary);
                border-radius: 10px;
                border: 2px solid var(--parla-primary);
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 4px 50px;
                height: 40px;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #1e9ce8;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #1e9ce8;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

@media only screen and (max-width: 990px) {
    .pricing-card-preview-wrapper {
        flex-direction: column;
        .pricing-card-item {
            width: 100%;
        }
    }
}
