$breakpoint-alpha: 480px; // adjust to your needs

.col-6 {
  .nested-table {
    justify-content: space-between;
  }
}

.nested-table {
  margin: 1em 0;
  min-width: 300px; // adjust to your needs
  display: flex;
  overflow: auto;

  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
  }

  td {
    display: block;

    .access-code-inputs {
      padding: 0, 0, 0, 0 !important;
    }

    // &:first-child {
    //   padding-top: .5em;
    // }
    // &:last-child {
    //   padding-bottom: .5em;
    // }

    &:before {
      content: attr(data-th) ": "; // who knew you could do this? The internet, that's who.
      font-weight: bold;

      // optional stuff to make it look nicer
      width: 6.5em; // magic number :( adjust according to your own content
      display: inline-block;
      // end options

      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th,
  td {
    text-align: left;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: 0.25em 0.5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.details-dropdown {
  width: 17%;
}

// presentational styling

body {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #444;
  background: #eee;
}

h1 {
  font-weight: normal;
  letter-spacing: -1px;
  color: #34495e;
}

.rwd-table {
  background: #34495e;
  color: #fff;
  border-radius: 0.4em;
  overflow: hidden;

  tr {
    border-color: lighten(#34495e, 10%);
  }

  th,
  td {
    margin: 0.5em 1em;

    @media (min-width: $breakpoint-alpha) {
      padding: 1em !important;
    }
  }

  th,
  td:before {
    color: #dd5;
  }
}

.context-action {
  cursor: pointer;
  display: flex;
  gap: 1rem;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem
  }
}

.action-edit-tags {
  cursor: pointer;
}

.rdt_Table {
  padding: 15px;

  .rdt_TableRow {
    cursor: pointer;
    height: 60px;

    .text-inverse,
    .asset-parent-wrapper {
      pointer-events: none;
    }

    .rdt_TableCell {
      >div:not(.custom-table-menu) {
        pointer-events: none;
      }

      .fields {
        max-height: 60px;
        overflow: hidden;
        align-self: start;

        .field {
          margin: 4px;
          font-size: 15px;
        }

        .hidden-field {
          margin: 2px;
          color: gray;
        }
      }
    }

    .rdt_TableRow:hover {
      background: #9bc4fc !important;
    }
  }
}

.custom-table-menu {
  .action-edit-tags {
    background-image: radial-gradient(#e9ecef, rgba(0, 0, 0, 0) 55%);
    border: none;
    color: #34495e;
  }

  .action-edit-tags:hover {
    background-image: radial-gradient(#e9ecef, rgba(0, 0, 0, 0) 85%);
  }

  .action-edit-tags:focus {
    background-image: radial-gradient(#c8d9eb, rgba(0, 0, 0, 0) 85%);
    box-shadow: none;
  }

  div[class*="TableCol"] {
    font-weight: 600;
    font-size: 16px;
  }
}

.rdt_TableCol {
  font-weight: 600 !important;
}

.name-entry {
  margin-right: 4px;

  &::after {
    content: ",";
  }

  &:last-child {
    margin-right: 0px;

    &::after {
      content: unset;
    }
  }
}