.card {
    border: none !important;
}

.dashboard-table-header {
    margin-bottom: 1em !important;
}

.dashboard-title {
    color: #102e4a;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0 5px;
}

.dashboard-header-subtitle {
    color: #727272;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.shortcut-card-wrap {
    display: flex;
    list-style: none;
    padding-left: 0;

    .shortcut-card {
        display: flex;
        align-items: center;
        padding: 14px;
        width: 50%;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 10px;
            font-size: 28px;
            min-width: 56px;
            height: 56px;
            margin-right: 26px;
        }

        .bottom-text {
            color: #4c4c4c;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &:first-child {
            margin-right: 10px;

            .main-text {
                color: var(--parla-primary);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .icon {
                background: linear-gradient(180deg, #56b8f3 0%, #109bef 100%);
            }
        }

        &:last-child {
            margin-left: 10px;

            .main-text {
                color: var(--parla-secondary);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .icon {
                background: linear-gradient(180deg, #f889b8 0%, #f40e6f 100%);
            }
        }
    }

    .shortcut-card:first-child {
        border-radius: 10px;
        background: #f2f9ff;
    }

    .shortcut-card:last-child {
        border-radius: 10px;
        background: #fff3f8;
    }
}

.dashboard-card-sm-title {
    color: #102e4a;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 24px 1rem 16px 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .availability-cta-wrap {
        display: flex;

        .availability-cta {
            display: flex;
            height: 50px;
            justify-content: center;
            align-items: center;
            border-radius: 8.963px;
            background: #f8f8f8;

            &:first-child {
                margin-right: 10px;
            }

            span {
                color: #1e9ce8;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                padding: 0 5px;
            }

            .react-toggle {
                margin-right: 5px;
            }
        }
    }
}

.dashboard-sales-card-info-wrap {
    display: flex;
    justify-content: space-between;

    .dashboard-sales-card-info {
        border-radius: 10px;
        background: #f9f9f9;
        padding: 15px;
        margin: 15px 25px 15px 0;
        width: 100%;

        .dashboard-sales-card-info-title {
            color: #4c4c4c;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            display: flex;
            justify-content: space-between;

            i {
                color: #1e9ce8;
            }
        }

        .dashboard-sales-card-info-value {
            color: #102e4a;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
        }

        .dashboard-sales-card-info-extra {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &:last-child {
            margin-right: 0;
        }

        .up {
            color: #39cf6c;
        }

        .down {
            color: #ed193f;
        }
    }
}

.departments-availability-table {
    .departments-availability-table-header {
        border-radius: 10px;
        background: #f8f8f8;
        color: #102e4a;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0 16px;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 0 0 15px;
        margin-bottom: 10px;

        .col-1-shop {
            width: 50%;
        }

        .col-2-dept {
            width: 50%;
        }

        .col-3-chat {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .col-4-call {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .department-availability {
        display: flex;
        padding: 0 0 0 35px;
        height: 25px;
        align-items: center;

        .col-1-shop {
            width: 50%;
            color: #102e4a;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .col-2-dept {
            width: 50%;
            color: #102e4a;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .col-3-chat {
            width: 100px;
            display: flex;
            align-items: center;
        }

        .col-4-call {
            width: 100px;
            display: flex;
            align-items: center;
        }
    }
}

.card-holder {
    .dashboard-card-tags-wrap {
        padding: 0 15px;
        display: flex;

        .dashboard-card-tag {
            color: #000;
            border-radius: 10px;
            display: flex;
            width: fit-content;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 4px 14px;
            margin-right: 5px;
            cursor: pointer;
            background: #eef7ff;

            &.active {
                color: #fff;
                background: #1e9ce8;
            }
        }
    }

    .card-body {
        padding: 0;
        margin: 1em;
        max-height: 21.5em;
        overflow-y: auto;
    }
}

.appointment-card-group {
    border-radius: 10px;
    margin-bottom: 10px;

    i {
        padding-right: 5px;
    }

    &.today {
        .appointment-inprogress {
            .time-remaining {
                padding-right: 10px;
                color: var(--ACCENT-SUCCESS, #39cf6c);
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .appointment-future {
            .time-remaining {
                padding-right: 10px;
                color: var(--ACCENT-ALERT, #ed193f);
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

    &.future {
        .time-remaining {
            padding-right: 10px;
            color: var(--ACCENT-WARNING, #f5b333);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}

.appointment-booked-card-day {
    border-radius: 10px;
    background: #e1f1ff;
    color: #1e9ce8;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 34px 11px 33px;
    display: inline-flex;
}

.appointment-card-wrap {
    background: linear-gradient(180deg, #f9fdff 0.1%, #f9fdff 100%);
    border-radius: 10px;
    margin: 5px;

    a {
        text-decoration: none;
    }

    .appointment-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .appointment-booked-card-name {
        color: #102e4a;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 12px;
        margin-left: 15px;
    }

    .appointment-booked-card-date {
        color: #4c4c4c;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 15px;
    }

    .appointment-booked-card-department {
        border-radius: 0px 0px 10px 10px;
        background: #deefff;
        color: #1e9ce8;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: inline-flex;
        height: 37px;
        padding: 6px 18px 5px 15px;
        width: 100%;
        align-items: center;
        margin-top: 12px;
        justify-content: space-between;
    }
}

.hr-text:after {
    content: " ";
}

.hr-text {
    text-align: center;

    &:after {
        flex: 1 1 auto !important;
        border-top: 1px solid #dee2e6 !important;
    }

    &:before {
        flex: 1 1 auto !important;
        border-top: 1px solid #dee2e6 !important;
    }
}

.media-body {
    margin-top: 0.4em;
    padding: 2em;
}

.dashboard-stats {
    margin-left: 5em;
}

.audit-log-table {
    overflow: auto;
}

.dashboard-wrapper {
    width: 100%;

    .help-icon {
        padding-top: 60px;
        margin-right: 20px;
    }
}

.react-toggle {
    .react-toggle-track {
        width: 45px;
        height: 20px;

        .react-toggle-track-x {
            right: 8px;
        }
    }

    .react-toggle-thumb {
        width: 18px;
        height: 18px;
    }
}