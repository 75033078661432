.livestream-page {
      width: 100%;

      .analytic-data {
            width: 100%;
            height: 110px;

            .analytic-header {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 15px;
                  padding: 0;

                  li {
                        padding: 10px;
                        list-style: none;
                        font-weight: 600;
                        color: #afafaf;

                        .number {
                              font-size: 45px;
                              display: block;
                              line-height: 40px;
                              color: #5e5e5f;
                              text-align: center;
                              margin-bottom: 10px;

                              i {
                                    padding-left: 30px;
                                    font-size: 40px;
                              }
                        }
                  }
            }
      }

      .livestream-page-content {
            display: flex;

            .product-list {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  height: 100%;
                  overflow: auto;
            }

            .product-tile {
                  position: relative !important;
                  width: 16rem !important;
                  min-height: 370px !important;
                  max-height: 27rem !important;
                  padding: 0.5em !important;
                  flex-shrink: 0 !important;
                  border-radius: 0.625rem !important;
                  background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8) !important;

                  .asset-parent-wrapper,
                  .tile-no-image {
                        display: flex !important;
                        width: 100% !important;
                        height: 7.5rem !important;
                        flex-shrink: 0 !important;
                        border-radius: 0.625rem !important;
                        background: #fff !important;
                        margin-bottom: 1.5em !important;

                        img {
                              display: block !important;
                              max-width: 15rem !important;
                              max-height: 7.5rem !important;
                              margin: auto !important;
                              border-radius: 0.625rem !important;
                        }
                  }

                  .tile-no-image {
                        margin-bottom: 0.3em !important;
                  }

                  .product-description {
                        padding: 0 !important;

                        .product-name {
                              color: #000 !important;
                              font-size: 0.875rem !important;
                              font-style: normal !important;
                              font-weight: 600 !important;
                              line-height: normal !important;
                              text-overflow: ellipsis !important;
                              overflow: hidden !important;
                              white-space: nowrap !important;
                              width: 100% !important;
                        }

                        .product-price {
                              color: var(--SECONDARY-PINK, #f40e6f) !important;
                              margin-top: 0.5em !important;
                              font-size: 0.875rem !important;
                              font-style: normal !important;
                              font-weight: 700 !important;
                              line-height: normal !important;
                        }
                  }

                  .item-details.order-item-grains {
                        margin: 0.75em 0 !important;
                        padding: 0.75em 0 !important;
                        border-top: 1px solid #d2d6db !important;
                        min-height: 137px !important;
                        max-height: 11em !important;

                        .item-detail {
                              min-height: 2em !important;
                              padding: 0.5em 0 !important;
                        }
                  }
            }

            .selected-products {
                  display: flex;
                  background: green;
            }

            .participants-card {
                  padding: 1em;
                  border-radius: 0.625rem;
                  border: 0.5em solid var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);

                  .call-room-id {
                        color: var(--ACCENT-ALERT, #ed193f);
                        display: block;
                        font-family: Montserrat;
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin-bottom: 1em;
                  }

                  .customer-card-panel {
                        border: none;
                        border-radius: 4px;
                        overflow-x: auto;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;

                        .customer-details {
                              display: flex;
                              flex-direction: column;
                              padding: 3px 15px;
                        }

                        .customer-card {
                              display: flex;

                              @media screen and (max-width: 768px) {
                                    flex-direction: column;
                                    gap: 1em;
                              }

                              img,
                              .customer-image {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 50px;
                                    width: 50px;
                                    object-fit: cover;
                                    margin: 3px;
                                    border-radius: 50%;
                                    background: #d2d6db;
                              }

                              .customer-buttons {
                                    display: flex;
                                    gap: 1em;
                                    margin: 0 1em;

                                    button {
                                          color: white;
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                          width: 2.5rem;
                                          height: 2.5rem;
                                          flex-shrink: 0;
                                          border-radius: 50%;
                                          background-color: #1e9ce8;
                                          border: none;

                                          &:disabled {
                                                opacity: 0.5;
                                          }
                                    }
                              }
                        }
                  }

                  .visits-mini-cards-wrapper {
                        display: flex;
                        justify-content: space-between;
                        padding: 0;

                        .visits-mini-card {
                              border-radius: 8px;
                              min-height: 100px;
                              height: 100%;
                              margin-bottom: 10px;
                              padding: 0px;
                              width: 100%;
                              margin-right: 15px;
                              font-size: 14px;
                              font-weight: 600;
                              border-radius: 0.725rem;

                              &.color-0 {
                                    .visits-mini-card-title {
                                          display: flex;
                                          padding: 0.625rem 1em;
                                          align-items: center;
                                          border-radius: 0.625rem 0.625rem 0rem 0rem;
                                          background: var(--ACCENT-SHADE_OF_BLUE_2, #dcecf9);
                                    }
                              }

                              &.color-1 {
                                    .visits-mini-card-title {
                                          display: flex;
                                          justify-content: space-between;
                                          padding: 0.625rem 1em;
                                          align-items: center;
                                          border-radius: 0.625rem 0.625rem 0rem 0rem;
                                          background: var(--ACCENT-SHADE_OF_PINK_1, #ffe7f1);

                                          a.journey-link {
                                                color: #102e4a !important;
                                          }
                                    }
                              }

                              &.color-2 {
                                    .visits-mini-card-title {
                                          display: flex;
                                          padding: 0.625rem 1em;
                                          align-items: center;
                                          border-radius: 0.625rem 0.625rem 0rem 0rem;
                                          background: var(--ACCENT-SHADE_OF_DARK_BLUE_1, #eff2f5);
                                    }
                              }

                              &.color-3 {
                                    border-radius: 0.625rem;
                                    background: var(--ACCENT-SHADE_OF_WHITE_2, #f4f4f4);
                              }

                              &:last-child {
                                    margin-right: 0px;
                              }

                              .visits-mini-card-value {
                                    color: var(--ACCENT-WHITE, #fff);
                                    font-size: 1.5rem;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    padding: 0.3rem 1rem;
                              }

                              .visits-mini-card-list {
                                    color: var(--ACCENT-SHADE_OF_DARK_BLUE_1, #eff2f5);
                                    font-size: 0.675rem;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    padding: 0.3rem 1rem;

                                    &.amount {
                                          padding: 0.5rem 1rem !important;
                                          font-size: 1.5rem !important;
                                          font-weight: 700 !important;
                                    }
                              }

                              .visits-mini-card-list-wrapper {
                                    display: flex;
                                    justify-content: space-between;

                                    .right {
                                          text-align: right;
                                    }

                                    .visits-mini-card-list {
                                          line-height: 14px;
                                          font-size: 13px;
                                          color: #fff;
                                    }
                              }

                              .add-new-order {
                                    font-size: 24px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    padding-top: 15px;
                              }
                        }
                  }
            }

            .product-livestream-grid {
                  display: flex;
                  align-items: flex-start;

                  .product-grid {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 -8px -8px 0;
                        padding: 0;
                        justify-content: center;
                        max-height: 600px;
                        overflow: auto;
                        margin-right: 15px;

                        li {
                              list-style: none;
                              height: 160px;
                              width: 150px;
                              margin: 10px;
                              background: #fff;
                              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

                              // border-top-left-radius: 4px;
                              // border-top-right-radius: 4px;
                              .product-card-selected-analitycs-wrap {
                                    position: sticky;
                                    bottom: 0;
                                    background: #ffffffa1;
                              }

                              .product-livestream-analytic-counts {
                                    display: flex;
                                    width: 100%;
                                    justify-content: space-around;
                                    align-items: center;
                                    padding-top: 10px;

                                    .like-counter[data-count]:after {
                                          content: attr(data-count);
                                          font-size: 10px;
                                          margin: 1px 0px 0px 2px;
                                          display: flex;
                                    }

                                    .product-livestream-like-count {
                                          display: flex;
                                          align-items: center;
                                          width: 34px;
                                          margin: 3px;
                                    }

                                    i {
                                          font-size: 16px;
                                    }

                                    color: #bfbfbf;

                                    .not-zero {
                                          color: rgb(27, 27, 27);
                                    }
                              }

                              &:hover {
                                    cursor: pointer;
                              }

                              .product-livestream-grid-name {
                                    font-size: 12px;
                                    padding: 7px 10px;
                                    margin-bottom: 0;
                                    max-width: 150px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                              }
                        }

                        img,
                        video {
                              max-height: 105px;
                              min-width: 100%;
                              object-fit: cover;
                              vertical-align: bottom;
                        }

                        .livestream-product {
                              opacity: 30%;
                              overflow: hidden;
                        }

                        .live {
                              opacity: 100%;
                        }
                  }
            }

            .remote-stacked {
                  position: relative;
                  height: auto;
                  width: 100%;
                  z-index: 1;
                  margin: auto;
            }

            .remote {
                  position: absolute;
                  max-height: 150px;
                  z-index: 1;
                  bottom: 10px;
                  right: 10px;
                  border: 2px solid #fff;
                  border-radius: 8px;
                  overflow: hidden;
            }

            canvas {
                  position: absolute;
                  left: 5px;
                  bottom: 10px;
                  background: #dcecf9;
                  border-radius: 50%;
                  height: 30px;
                  width: 30px;
                  overflow: hidden;
                  padding: 3px;
            }

            .maximixe-show-btn {
                  display: flex;
                  justify-content: start;
                  position: absolute;
                  left: 0em;
                  top: 0em;
                  font-size: 1.5em;
                  width: 1.5em;
                  height: 2em;
                  color: #fff;
                  background: rgb(0, 0, 0);
                  background: linear-gradient(150deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 75%);
                  border-top-left-radius: 0.4em;
                  border-bottom-right-radius: 100%;
                  z-index: 10;
                  cursor: pointer;

                  i {
                        padding-top: 0.3em;
                        padding-left: 0.2em;
                  }
            }

            .minimize-show-btn {
                  position: fixed;
                  right: 10px;
                  top: 70px;
                  z-index: 2;
                  padding: 10px;
                  cursor: pointer;

                  i {
                        text-shadow:
                              0px 0px 5px white,
                              0px 0px 5px white,
                              0px 0px 5px white,
                              0px 0px 5px white,
                              0px 0px 5px white;
                  }
            }

            .network {
                  position: absolute;
                  z-index: 9;
                  margin: 5px 10px;
                  right: 0;

                  .network-alert-popup {
                        position: fixed;
                        background: #1c1c1c;
                        width: 96%;
                        left: 0;
                        top: 60px;
                        border-radius: 8px;
                        height: 50px;
                        margin: 2%;
                        z-index: 99;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        padding: 0 15px;
                        box-sizing: border-box;
                        font-size: 14px;

                        i {
                              font-size: 22px;
                              padding-right: 15px;
                              text-align: center;
                        }

                        b {
                              display: block;
                              width: 100%;
                        }

                        span {
                              width: calc(100% - 30px);
                        }
                  }

                  .fad {
                        --fa-primary-color: #ed193f;
                  }
            }

            @media screen and (max-width: 760px) {
                  .network {
                        .network-alert-popup {
                              top: 35px;
                        }
                  }
            }

            .video-frame {
                  display: flex;
                  justify-content: center;
            }

            .show-and-sell-frame {
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 200px;
                  justify-content: center;
                  align-items: center;

                  .audio-only-host {
                        position: absolute;
                        bottom: 0px;
                        z-index: 5;
                        right: 100px;
                  }

                  &.video-track-col {
                        // flex-direction: column-reverse;

                        .remote-frame {
                              position: unset;
                              width: 100% !important;
                              height: auto !important;
                        }
                  }

                  .remote-frame {
                        position: absolute;
                        max-width: 100%;
                        top: 0px;
                  }
            }

            @media screen and (max-width: 990px) {
                  .show-and-sell-frame {
                        &.video-track-col {
                              .remote-frame {
                                    .maximixe-show-btn {
                                          display: none;
                                    }

                                    .remote-video-preview {
                                          width: 100%;
                                    }

                                    .remote {
                                          margin-top: 0px !important;
                                          margin-left: 0px !important;
                                          margin-bottom: 0px !important;
                                          min-width: 100%;
                                          height: 250px;

                                          .livestream-video-preview {
                                                border-radius: 8px;
                                          }
                                    }
                              }
                        }

                        .remote-frame {
                              align-self: center;
                        }
                  }
            }

            @media only screen and (max-device-height: 500px) and (orientation: landscape) {
                  .show-and-sell-frame {
                        &.video-track-col {
                              flex-direction: row-reverse !important;
                              align-items: end;
                        }

                        .remote-frame {
                              margin-bottom: -5px;
                              padding-left: 5px;
                              align-self: start;

                              .remote-stacked {
                                    height: calc(100vh - 120px);

                                    .remote-video-preview {
                                          width: unset;
                                    }
                              }
                        }
                  }
            }

            .livestream-call {
                  display: flex;
                  width: auto;
                  z-index: 1;
                  padding: 0;
                  flex-direction: column;

                  // margin-bottom: 5px;
                  @media screen and (max-width: 680px) {
                        .placeholder-div-for-livestream {
                              height: 400px;
                        }
                  }

                  .remote .livestream-no-video {
                        width: 100%;
                        height: 100%;
                        border-radius: 18px;
                  }

                  .remote .livestream-video-muted {
                        position: absolute;
                        left: -1%;
                        top: -1%;
                        width: 102%;
                        height: 102%;
                        background: lightgray;
                        border-radius: 15px;
                        border: 2px solid white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        color: #666;
                  }

                  .livestream-audio-muted {
                        display: flex;
                        justify-content: end;
                        position: absolute;
                        right: 0em;
                        top: 0em;
                        font-size: 1.5em;
                        width: 1.5em;
                        height: 2em;
                        color: #fff;
                        background: rgb(0, 0, 0);
                        background: linear-gradient(211deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 75%);
                        border-top-right-radius: 0.4em;
                        border-bottom-left-radius: 100%;

                        i {
                              padding-top: 0.3em;
                              padding-right: 0.2em;
                        }
                  }

                  .Participants {
                        position: absolute;
                        width: 100%;
                  }

                  .remote .remote-video-preview {
                        width: 200px;
                        height: 150px;
                        object-fit: contain;
                        background: #272727;
                  }

                  .hide {
                        display: none;
                  }

                  .badge-video {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        background-color: #fff;
                        margin: auto;
                        position: relative;

                        span {
                              text-align: center;
                              border-radius: 0.625rem 0.625rem 0rem 0rem;
                              color: var(--TERTIARY-DARK_BLUE, #102e4a);
                              font-family: Montserrat;
                              font-size: 0.75rem;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              border-radius: 20px;
                              border-radius: 1.875rem;
                              background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);
                              width: 17rem;
                              padding: 0.75rem 1.875rem 0.8125rem 1.8125rem;
                              margin-top: 15px;
                              position: absolute;
                              z-index: 2;

                              &.live {
                                    background: red;
                                    border: 1px solid #86170f;
                              }
                        }
                  }

                  .remote-video-info {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        text-align: center;
                        display: block;
                        background: black;
                        border-radius: 15px;
                        opacity: 0.2;
                        color: white;
                        font-weight: bold;
                        padding-top: 60px;
                  }

                  .remote-video-info-customer {
                        margin: auto;
                        color: #313131;
                        background: #ffffff8f;
                        padding: 8px 15px;
                        border-radius: 18px;
                        font-weight: 600;
                        height: inherit;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                  }

                  .header-message-block {
                        position: relative;
                        flex-shrink: 0;
                        border-radius: 10px 10px 0 0;
                        background: var(--ACCENT-SHADE_OF_BLUE_1, #eef7ff);

                        .user-card {
                              display: flex;
                              padding: 4px;
                              align-items: center;
                              gap: 10px;
                              width: 250px;
                              border-radius: 20px;

                              i {
                                    font-size: 26px;
                                    padding-left: 0;
                              }

                              label {
                                    display: block;
                                    color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                    font-family: Montserrat;
                                    font-size: 0.875rem;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    font-size: 12px;
                              }

                              span {
                                    display: block;
                                    color: var(--ACCENT-SHADE_OF_BLACK_4, #4c4c4c);
                                    font-family: Montserrat;
                                    font-size: 0.75rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                              }
                        }
                  }

                  .livestream-vc-preview {
                        position: relative;
                        width: 100%;
                        align-self: start;
                        display: flex;
                        justify-content: center;

                        .livestream-frame {
                              position: absolute;
                              margin: auto;
                              margin-bottom: 6.5px;

                              @media screen and (max-width: 680px) {
                                    height: 400px;
                              }
                        }

                        .track-errors {
                              position: absolute;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              flex-direction: column;
                              padding-top: 120px;
                              z-index: 2;
                              pointer-events: none;
                              width: 100%;

                              .track-error {
                                    font-weight: 600;
                                    color: red;
                                    margin: 10px;
                              }
                        }

                        .livestream-reference {
                              width: 100%;
                              height: calc(100vh - 120px);
                              position: absolute;
                              pointer-events: none;
                        }

                        .livestream-control-buttons {
                              z-index: 5;
                              display: flex;
                              justify-content: center;
                              margin: 10px 0;
                              position: absolute;
                              right: 10px;
                              bottom: 0;

                              .connect-call {
                                    background: red;
                                    border: none;
                                    padding: 8px 15px;
                                    border-radius: 21px;
                                    color: #fff;

                                    i {
                                          padding-right: 5px;
                                    }
                              }
                        }
                  }

                  .start-call-wrapper {
                        label {
                              color: var(--TERTIARY-DARK_BLUE, #102e4a);
                              margin-top: 1em;
                              margin-bottom: 0.5em;
                              font-family: Montserrat;
                              font-size: 0.875rem;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;

                              span {
                                    color: var(--PRIMARY-BLUE, #1e9ce8);
                                    font-family: Montserrat;
                                    font-size: 0.875rem;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                              }
                        }

                        .start-call {
                              margin-bottom: 2em;
                        }
                  }

                  .start-call {
                        border: none;
                        padding: 8px 15px;
                        border-radius: 21px;
                        color: #fff;
                        height: 45px;
                        border-radius: 0.625rem;
                        background: var(--PRIMARY-BLUE, #1e9ce8);
                        color: var(--Shade_1-White, #fff);
                        text-align: center;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;

                        i {
                              padding-right: 5px;
                        }
                  }

                  .quality-control {
                        font-size: 9px;
                        font-weight: 600;
                  }

                  .video-track {
                        margin: auto;
                        width: fit-content;
                  }

                  .livestream-video-preview {
                        width: 100%;
                        height: 100%;
                        border: none;
                        border-bottom-left-radius: 0.625rem;
                        border-bottom-right-radius: 0.625rem;
                        object-fit: cover;

                        @media screen and (max-width: 680px) {
                              height: 400px;
                        }
                  }

                  .flip-Y {
                        transform: scale(-1, 1);
                  }

                  .livestream-no-video {
                        height: 100%;
                        border: white;
                        border-width: 1pt;
                        object-fit: cover;
                        border-radius: 0 0 0.625rem 0.625rem;
                        background: var(--ACCENT-WHITE, #fff);
                        margin: auto;

                        h3 {
                              text-align: center;
                              color: var(--TERTIARY-DARK_BLUE, #102e4a);
                              font-family: Montserrat;
                              font-size: 1.125rem;
                              font-style: normal;
                              font-weight: 700;
                              line-height: normal;
                              margin-top: 1em;
                        }

                        @media screen and (max-width: 680px) {
                              height: 400px;
                        }
                  }

                  .product-grid-small {
                        height: 600px;

                        .product-grid {
                              position: absolute;
                              left: 0;
                              list-style: none;
                              height: 100px;
                              overflow: hidden;
                              display: flex;
                              flex-direction: column-reverse;
                              margin-top: 5px;

                              .livestream-product {
                                    display: none;
                              }

                              .live {
                                    display: block;
                              }

                              li {
                                    div {
                                          display: none;
                                    }

                                    label {
                                          display: none;
                                    }
                              }

                              img {
                                    width: 40px;
                                    height: 40px;
                                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                    border: 1px solid #fff;
                                    margin: 5px 6px;
                              }
                        }
                  }

                  .preview-chat-smal {
                        position: absolute;
                        height: 240px;
                        width: auto;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column-reverse;
                        margin-top: 325px;

                        .msg-board {
                              padding: 0 20px;

                              .msg-input {
                                    display: none;
                              }

                              .msg {
                                    color: #fff;
                                    background: #000000a8;
                                    padding: 5px 10px;
                                    border-radius: 18px;
                                    width: fit-content;
                                    display: flex;
                                    line-height: 16px;
                                    font-weight: normal;

                                    .msg-user {
                                          font-size: 12px;
                                          color: #fff;
                                          white-space: nowrap;
                                          font-weight: normal;

                                          i {
                                                display: none;
                                          }
                                    }
                              }
                        }

                        .msg-guest {
                              right: 0;
                        }

                        .reader {
                              overflow: hidden;
                              position: relative;

                              .reader-board {
                                    display: flex;
                                    flex-direction: column-reverse;
                                    position: absolute;
                                    bottom: 0;
                              }
                        }

                        .input-send-msg {
                              .msg-input {
                                    width: 265px;
                                    height: 35px;
                                    border: none;
                                    padding: 12px;
                                    font-size: 12px;
                                    border-radius: 18px;
                                    background: #eaeaea;
                                    padding-right: 40px;
                                    outline: none;
                                    pointer-events: none;
                              }

                              a {
                                    display: none;
                              }
                        }
                  }

                  .section-footer {
                        height: 32px;
                        overflow: hidden;
                        background: #ffffff;
                        width: 311px;
                        display: flex;
                        position: absolute;
                        margin-top: 538px;
                        align-items: center;
                        justify-content: center;

                        .rhs-footer {
                              position: absolute;
                              right: 8px;
                              padding-right: 5px;
                              font-size: 9px;
                              font-weight: 600;
                              display: flex;
                              flex-direction: column;
                              text-align: right;
                              width: auto;

                              img {
                                    padding-left: 10px;
                                    height: 7px;
                              }
                        }

                        img {
                              height: 20px;
                        }
                  }
            }

            .livestream-call-detaills {
                  width: 100%;
                  margin-right: 15px;

                  .product-grid-small {
                        height: 600px;

                        .product-grid {
                              position: absolute;
                              left: 0;
                              list-style: none;
                              height: 100px;
                              overflow: hidden;
                              display: flex;
                              flex-direction: column-reverse;
                              margin-top: 5px;

                              .livestream-product {
                                    display: none;
                              }

                              .live {
                                    display: block;
                              }

                              li {
                                    div {
                                          display: none;
                                    }

                                    label {
                                          display: none;
                                    }
                              }

                              img {
                                    width: 40px;
                                    height: 40px;
                                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                                    border: 1px solid #fff;
                                    margin: 5px 6px;
                              }
                        }
                  }
            }

            .msg-board {
                  overflow: hidden;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;

                  .reader {
                        height: auto;
                        min-height: 200px;
                        overflow: auto;
                        position: relative;
                        overflow-anchor: none;

                        .reader-board {
                              display: flex;
                              flex-direction: column-reverse;
                              bottom: 0;
                              min-height: 150px;
                              overflow: auto;
                              width: 100%;
                        }

                        &:after {
                              content: "";
                              display: block;
                              overflow-anchor: auto;
                              height: 1px;
                        }
                  }

                  .msg-input {
                        width: calc(100% - 32px);
                        height: 35px;
                        border: none;
                        padding: 12px;
                        font-size: 12px;
                        border-radius: 18px;
                        background: #eaeaea;
                        padding-right: 40px;
                        outline: none;
                  }

                  .msg {
                        background: #4242421c;
                        max-width: 80%;
                        margin-bottom: 8px;
                        border-radius: 25px;
                        padding: 4px 10px;
                        font-size: 12px;
                        line-height: 16px;
                  }

                  .msg-guest {
                        position: relative;
                        right: calc(-20% + 30px);
                        text-align: right;
                  }

                  .msg-user {
                        font-size: 10px;
                        color: #5e5e5f;
                  }

                  .input-send-msg {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;

                        a {
                              position: relative;
                              background: #b1b1b1;
                              border-radius: 50%;
                              margin-top: -32px;
                              height: 28px;
                              width: 28px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              font-size: 13px;
                        }
                  }
            }
      }
}

.show-sell-page {
      .livestream-page-content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .accordion {
                  width: 100%;
                  margin-left: 15px;

                  .card-header {
                        font-weight: 600;
                        background: #fff;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                  }

                  .card-body {
                        .appointment-and-notes {
                              display: flex;

                              @media screen and (max-width: 768px) {
                                    flex-wrap: wrap;
                              }

                              gap: 1em;
                              margin-top: 1em;
                              border-radius: 0.625rem;
                              background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);
                              padding: 0.5em;

                              .appointment-details {
                                    border-radius: 0.625rem;
                                    background: var(--ACCENT-WHITE, #fff);
                                    display: inline-flex;
                                    padding: 1rem;
                                    width: 100%;
                                    min-width: 50%;

                                    .appointment-notes-wrap {
                                          [contenteditable]:hover {
                                                background-color: transparent;
                                          }
                                    }

                                    @media screen and (max-width: 768px) {
                                          min-width: 100%;
                                    }

                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    gap: 1rem;

                                    .appointment-details-title {
                                          color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                          font-size: 1rem;
                                          font-style: normal;
                                          font-weight: 700;
                                          line-height: normal;
                                    }

                                    .appointment-details-item {
                                          color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                          font-size: 0.875rem;
                                          font-style: normal;
                                          font-weight: 500;
                                          line-height: normal;
                                    }
                              }

                              .notes-card {
                                    margin-top: 0;
                                    padding: 1em 1.25em;
                                    border-radius: 0.625rem;
                                    background: var(--ACCENT-WHITE, #fff);

                                    textarea {
                                          margin-top: 1.3em;
                                          display: flex;
                                          width: 100%;
                                          height: 9.8125rem;
                                          padding: 1rem;
                                          align-items: center;
                                          flex-shrink: 0;
                                          border-radius: 0.625rem;
                                          border: 1px solid var(--ACCENT-SHADE_OF_DARK_BLUE_2, #d2d6db);

                                          &::placeholder {
                                                color: var(--ACCENT-SHADE_OF_BLACK_5, #949494);
                                                font-size: 0.875rem;
                                                font-style: normal;
                                                font-weight: 500;
                                                line-height: normal;
                                          }
                                    }
                              }

                              .journey-follow-up {
                                    padding: 1em;
                                    margin-top: 0.5em;
                                    border-radius: 0.625rem;
                                    background: var(--ACCENT-WHITE, #fff);

                                    button,
                                    a {
                                          margin-top: 1em;
                                          font-size: 0.875rem;
                                          font-style: normal;
                                          font-weight: 600;
                                          line-height: normal;
                                          border-radius: 0.625rem;
                                          padding: 0.75em 1em;
                                          width: 100%;
                                    }

                                    .view-journey {
                                          color: var(--PRIMARY-BLUE, #1e9ce8);
                                          background: var(--ACCENT-SHADE_OF_BLUE_1, #eef7ff);
                                    }

                                    .follow-up {
                                          color: #fff;
                                          background: var(--PRIMARY-BLUE, #1e9ce8);
                                    }
                              }
                        }
                  }
            }

            .call-selector-wrapper {
                  flex-grow: 1;

                  .filters {
                  }
            }

            .product-selection {
                  height: 600px;
            }

            .selected-products-show-sell {
                  ul {
                        display: flex;
                        font-size: 10px;
                        overflow-x: auto;
                        padding: 5px 0;
                        margin-bottom: 15px;
                        min-height: 50px;

                        // width: 660px;
                        flex-wrap: wrap;

                        @media screen and (max-width: 500px) {
                              flex-wrap: nowrap;
                        }

                        max-height: 415px;
                        position: relative;
                  }

                  label {
                        width: 100%;
                  }

                  .product-card {
                        position: relative;
                        width: 16rem;
                        min-height: 370px;
                        max-height: 27rem;
                        padding: 1em;
                        flex-shrink: 0;
                        border-radius: 0.625rem;
                        background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);

                        .close-product-card {
                              position: absolute;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background-color: #ed193f;
                              top: -1em;
                              right: -5px;
                              width: 2rem;
                              height: 2rem;
                              flex-shrink: 0;
                              border-radius: 50%;

                              i {
                                    font-size: 1.7em;
                                    color: white;
                              }
                        }

                        .asset-parent-wrapper {
                              display: flex;
                              width: 100%;
                              height: 7.5rem;
                              flex-shrink: 0;
                              border-radius: 0.625rem;
                              background: #fff;
                              margin-bottom: 1.5em;

                              img {
                                    display: block;
                                    max-width: 15rem;
                                    max-height: 7.5rem;
                                    margin: auto;
                              }
                        }

                        .product-card-selected-analitycs-wrap {
                              .item-details.order-item-grains {
                                    margin: 0.75em 0;
                                    padding: 0.75em 0;
                                    border-top: 1px solid #d2d6db;
                                    border-bottom: 1px solid #d2d6db;
                                    min-height: 137px;
                                    max-height: 11em;

                                    .item-detail {
                                          min-height: 2em;
                                          padding: 0.5em 0;
                                    }
                              }

                              .product-livestream-analytic-counts {
                                    display: flex;
                                    flex-direction: row;

                                    .analytic-icon-and-value {
                                          display: flex;
                                          width: 3.125rem;
                                          padding: 0.25rem 0.625rem;
                                          align-items: baseline;
                                          gap: 0.625rem;
                                          border-radius: 0.625rem;

                                          /* SMALL TEXT/SEMIBOLD */
                                          font-family: Montserrat;
                                          font-size: 0.75rem;
                                          font-style: normal;
                                          font-weight: 600;
                                          line-height: normal;

                                          &.viewed {
                                                background: #d9e3ed;
                                                color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                          }

                                          &.liked {
                                                background: var(--ACCENT-SHADE_OF_PINK_1, #ffe7f1);
                                                color: var(--SECONDARY-PINK, #f40e6f);
                                          }

                                          &.added-to-cart {
                                                background: #dcecf9;
                                                color: var(--PRIMARY-BLUE, #1e9ce8);
                                          }

                                          &.billed {
                                                background: #d4f3d4;

                                                color: var(--ACCENT-ONLINE, #4fb500);
                                          }
                                    }
                              }
                        }
                  }

                  li {
                        list-style: none;
                        height: 160px;
                        width: 150px;
                        margin: 5px;
                        // background: #fff;
                        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
                        border-radius: 0.625rem;
                        background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);

                        .product-livestream-analytic-counts {
                              display: flex;
                              width: 100%;
                              justify-content: space-around;
                              align-items: center;
                              flex-direction: column;
                              padding-bottom: 10px;
                              color: #bfbfbf;

                              .like-counter[data-count]:after {
                                    content: attr(data-count);
                                    font-size: 10px;
                                    margin: 1px 0px 0px 2px;
                              }

                              .product-livestream-like-count {
                                    align-items: center;
                                    display: flex;
                                    width: 34px;
                                    margin-bottom: 0;

                                    i {
                                          font-size: 16px;
                                    }
                              }

                              .not-zero {
                                    color: rgb(27, 27, 27);
                              }

                              .product-livestream-analytic-counts-row {
                                    display: flex;
                                    width: 100%;
                                    justify-content: space-around;
                                    margin-top: 6px;
                              }
                        }

                        &:hover {
                              cursor: pointer;
                        }

                        .product-livestream-grid-name {
                              color: #000;
                              font-family: Montserrat;
                              font-size: 0.875rem;
                              font-style: normal;
                              font-weight: 600;
                              line-height: normal;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;
                        }
                  }

                  .product-card-name {
                        min-width: 70px;
                        height: 50px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: flex;
                        align-items: flex-start;
                        padding: 2px;
                  }
            }

            .selected-products-show-sell-hide {
                  display: none;
            }

            .one-to-one-analytics {
                  position: initial;
                  width: 100%;
                  margin-bottom: 10px;
                  box-shadow: none;
                  border-radius: 0.625rem;
                  background: var(--ACCENT-WHITE, #fff);
                  height: auto !important;

                  .title {
                        color: var(--PRIMARY-BLUE, #1e9ce8);
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-bottom: 1em;
                  }

                  .live {
                        color: var(--ACCENT-ALERT, #ed193f);
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        border-radius: 0.625rem !important;
                        background: #ffe7e7 !important;

                        i {
                              display: inline-block;
                              width: 0.5rem;
                              height: 0.5rem;
                              background-color: var(--ACCENT-ALERT, #ed193f);
                              border-radius: 50%;
                              margin-right: 0.3em;
                        }
                  }

                  ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        gap: 1em;
                        padding: 0;

                        li {
                              display: flex;
                              flex-direction: column-reverse;
                              gap: 1em;
                              min-width: 7.875rem;
                              height: 6.25rem;
                              padding: 1.125rem 2.21875rem;
                              justify-content: center;
                              align-items: center;
                              flex-shrink: 0;

                              span {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    min-width: 66px;

                                    &:nth-child(1) {
                                          font-size: 0.75rem;
                                          width: 50px;
                                    }

                                    &:nth-child(2) {
                                          font-size: 1.5rem;
                                    }
                              }

                              &:nth-child(1) {
                                    border-radius: 0.625rem;
                                    background: #d9e3ed;

                                    span {
                                          color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                    }
                              }

                              &:nth-child(2) {
                                    border-radius: 0.625rem;
                                    background: #ffe7f1;

                                    span {
                                          color: var(--SECONDARY-PINK, #f40e6f);
                                    }
                              }

                              &:nth-child(3) {
                                    border-radius: 0.625rem;
                                    background: #dcecf9;

                                    span {
                                          color: var(--PRIMARY-BLUE, #1e9ce8);
                                    }
                              }

                              &:nth-child(4) {
                                    border-radius: 0.625rem;
                                    background: #d4f3d4;

                                    span {
                                          color: var(--ACCENT-ONLINE, #4fb500);
                                    }
                              }
                        }
                  }
            }
      }

      .ss-action-btns-wrap {
            display: flex;
            justify-content: space-between;

            a {
                  text-decoration: none;
            }
      }

      .livestream-notes-component {
            textarea {
                  width: 100%;
                  border: 1px solid #ccc;
            }
      }

      .journey-link {
            display: flex;
            justify-content: flex-end;
      }

      .journey-link a {
            background: rgb(31, 31, 31);
            border-radius: 10px;
            padding: 3px 9px;
            text-decoration: none;
            color: white;

            i {
                  margin-left: 5px;
            }
      }

      .help-label {
            font-size: 12px;
      }

      .card-title {
            margin-right: 15px;
      }

      .call-info {
            margin-right: 15px;
            min-height: 50px;
            display: flex;
            align-content: center;

            .call-appointment-room-details {
                  background: #247c41;
                  padding: 6px 12px;
                  border-radius: 22px;
                  color: #fff;
                  height: 36px;
                  margin-left: 5px;
            }
      }

      .call-appointment-room-details-expired {
            @extend .call-appointment-room-details;
            background: #942121;
      }

      .call-appointment-room-details-not-started {
            @extend .call-appointment-room-details;
            background: #89c4fc;
      }

      .call-appointment-room-name {
            font-weight: 600;
            font-size: 18px;
            margin-right: 15px;
      }

      .logs {
            position: absolute;
            right: 30px;
      }

      .livestream-page-content.col-wrap {
            flex-direction: column;
      }

      .call-closed {
            background: #ed193f;
            color: #fff;
            font-weight: 600;
            margin: 10px 0px 10px 0px;
            padding: 15px 20px;
            border-radius: 4px;
      }

      .call-timeline {
            .participant-graph {
                  width: 100%;
                  height: 150px;
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  padding: 15px;
                  border-radius: 0.625rem;
                  background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);

                  .legend {
                        border-radius: 0.625rem;
                        background: var(--ACCENT-WHITE, #fff);
                        display: flex;
                        flex-direction: column;

                        .user {
                              color: #8884d8;
                        }

                        .customer {
                              color: #f34199;
                        }

                        .call-time-label {
                              color: var(--PRIMARY-BLUE, #1e9ce8);
                              font-size: 1rem;
                              font-style: normal;
                              font-weight: 700;
                              line-height: normal;
                              padding: 0.5em;
                        }
                  }

                  .custom-tooltip {
                        display: flex;
                        align-items: center;
                        padding: 0.25em;

                        .intend {
                              width: 0.375rem;
                              height: 2.25rem;
                              flex-shrink: 0;
                              border-radius: 0.625rem;
                        }

                        .content {
                              display: flex;
                              flex-direction: column;
                              padding: 0.5em;

                              label {
                                    color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                    font-size: 0.75rem;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                              }

                              span {
                                    margin-top: 0.2em;
                                    font-size: 0.625rem;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                              }
                        }

                        &.customer {
                              .intend {
                                    background: var(--SECONDARY-PINK, #f40e6f);
                              }

                              span {
                                    color: var(--SECONDARY-PINK, #f40e6f);
                              }

                              background: var(--ACCENT-SHADE_OF_PINK_1, #ffe7f1);
                        }

                        &.user {
                              .intend {
                                    background: var(--PRIMARY-BLUE, #1e9ce8);
                              }

                              span {
                                    color: var(--PRIMARY-BLUE, #1e9ce8);
                              }

                              background: var(--ACCENT-SHADE_OF_BLUE_2, #dcecf9);
                        }

                        border-radius: 0.625rem;
                  }

                  svg {
                        .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
                              border-radius: 0.625rem;
                              background: var(--ACCENT-WHITE, #fff);

                              text {
                                    color: var(--TERTIARY-DARK_BLUE, #102e4a);
                                    font-size: 0.75rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                              }
                        }
                  }
            }
      }
}

@media screen and (max-width: 1300px) {
      .show-sell-page,
      .livestream-page {
            width: 100% !important;

            .livestream-page-content {
                  flex-direction: column;

                  .visits-mini-cards-wrapper {
                        flex-wrap: wrap;

                        .visits-mini-card {
                              margin-bottom: 3px;
                        }
                  }

                  .visits-mini-card {
                        margin-right: 0 !important;
                  }

                  .product-selection {
                        width: 100%;
                  }
            }

            .selected-products-show-sell {
                  display: flex;
                  flex-wrap: wrap;
            }
      }
}

@media screen and (max-width: 990px) {
      .customer-view {
            .related-journeys {
                  .visits-mini-cards-wrapper {
                        .visits-mini-card {
                              margin-bottom: 5px;
                        }
                  }
            }
      }

      .show-sell-page {
            width: 100% !important;

            .data-veiw-footer {
                  display: none;
            }

            .livestream-page-content {
                  flex-direction: column;
                  height: fit-content;

                  .accordion {
                        margin-left: 0;
                  }

                  .visits-mini-card {
                        margin-bottom: 3px;
                  }
            }
      }

      .show-sell-page {
            margin-top: 0 !important;
      }
}

@media only screen and (max-width: 767px) {
      .livestream-page {
            .livestream-page-content {
                  .remote {
                        height: 130px;
                  }

                  .minimize-show-btn {
                        top: 40px;
                  }

                  .livestream-call {
                        .start-call,
                        .stop-call {
                              height: 48px;
                        }

                        .main-action.stop-call {
                              display: none;
                        }
                  }
            }

            .card-body {
                  padding: 5px;
            }

            .livestream-control-buttons {
                  .in-call-controls {
                        align-items: center;
                        justify-content: initial;

                        .call-control-menu-wrap {
                              position: absolute;
                              right: 10px;
                              bottom: 50px;
                              z-index: 1;
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                        }

                        &:has(.start-call) .call-control-menu-wrap {
                              bottom: 100px;
                        }

                        .controls {
                              .btn-description {
                                    display: none;
                              }
                        }

                        &.hidden {
                              display: none;
                        }
                  }
            }
      }
}

.video-tracks {
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 9;
}

.audio-tracks {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      z-index: 9;
}

.livestream-control-buttons {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;

      .popup {
            display: flex;
            flex-direction: row;
            height: 70px;
            opacity: 1;
            transition: all 0.4s;
            max-width: 100%;
            overflow-y: hidden;
            overflow-x: auto;

            &.hidden {
                  height: 0px;
                  opacity: 0;
                  overflow: hidden;
            }

            .popup-option {
                  font-size: 0.8em;
                  cursor: pointer;
                  min-width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  background: var(--parla-primary);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: white;
                  margin: 10px;

                  &:hover,
                  &.selected {
                        background-color: #deefff;
                        color: var(--parla-primary);
                        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                  }

                  &.option-camera {
                        border-radius: 8px;
                        padding: 0 5px;
                  }
            }

            @media screen and (max-width: 680px) {
                  flex-direction: column;
                  position: absolute;
                  bottom: 7em;
                  right: 4.6em;
                  height: 20em;
                  overflow: auto;
                  background: #fff;
                  border-radius: 0.3125rem;
                  z-index: 1;
                  padding: 0.5em 0em;
                  max-height: calc(100vh - 23.7em);
                  overflow: auto;

                  .popup-option {
                        min-height: 50px;
                        margin: 0.1em 0.5em;
                  }
            }
      }

      .quality-popup-arrow {
            position: absolute;
            top: -3.5em;
            right: 75px;
            font-size: 2em;
            color: white;
            transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
      }

      .in-call-controls {
            display: flex;

            justify-content: center;
            max-width: 1000px;
            width: 100%;
            margin: 1em 0em;
            overflow-x: auto;
            padding-bottom: 10px;

            .more-btn {
                  display: none;
            }

            @media screen and (max-width: 680px) {
                  flex-wrap: wrap;
                  justify-content: center;

                  .more-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #fff;
                        color: #102e4a;
                        cursor: pointer;
                        width: 45px;
                        height: 45px;
                        border-radius: 5.175rem;
                        -webkit-backdrop-filter: blur(4px);
                        backdrop-filter: blur(4px);
                        z-index: 1;
                        box-shadow: 0px 0px 4px 0px black;
                        font-size: 18px;
                  }
            }

            .controls {
                  display: flex;

                  .vid-btn-wrap {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        min-width: 90px;
                        align-items: center;

                        button {
                              color: #fff;
                              width: 3rem;
                              height: 3rem;
                              flex-shrink: 0;
                              border-radius: 5.175rem;
                              background: var(--PRIMARY-BLUE, #1e9ce8);
                              backdrop-filter: blur(4px);
                              border: none;

                              &.stop-call {
                                    background: var(--ACCENT-ALERT, #ed193f);
                                    margin: 0;

                                    @media screen and (min-width: 767px) {
                                          display: none;
                                    }
                              }

                              .quality-txt {
                                    font-size: 0.7em;
                              }
                        }

                        .btn-description {
                              color: #363636;
                              white-space: nowrap;
                              font-size: 12px;
                              text-align: center;
                              line-height: 30px;
                              padding: 0 5px;
                              border-radius: 8px;
                              font-weight: bold;
                        }
                  }

                  @media screen and (max-width: 680px) {
                        flex-direction: column;
                        position: absolute;
                        right: 0;
                        bottom: 55px;
                        gap: 0.5em;
                        border-radius: 0.3125rem;
                        background: var(--ACCENT-WHITE, #fff);
                        padding: 0.5em;
                        max-height: calc(100vh - 265px);
                        overflow: auto;
                        z-index: 1;
                        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

                        &.show-controls {
                              display: flex;
                        }

                        &.hide-controls {
                              display: none;
                        }

                        .vid-btn-wrap {
                              min-width: 0;

                              .btn-description {
                                    display: none;
                                    flex-direction: column;
                                    position: absolute;
                                    top: -24em;
                                    right: -0.22em;
                                    gap: 1em;
                              }
                        }
                  }
            }

            .main-action {
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 3rem;
                  flex-shrink: 0;
                  border-radius: 0.625rem;
                  border: none;
                  padding: 0 20px;

                  &.stop-call {
                        background: var(--ACCENT-ALERT, #ed193f);
                  }

                  &.start-call {
                        background: var(--PRIMARY-BLUE, #1e9ce8);
                  }

                  svg {
                        margin-right: 0.5em;
                  }

                  @media screen and (max-width: 680px) {
                        margin: auto;
                        width: 100%;
                        max-width: 300px;
                  }
            }

            &.hidden {
                  display: none;
            }
      }
}
